<template>
  <div class="schedule-wrapper">
    <section class="schedule-item">
      <el-divider content-position="left">
        <span class="required-icon">*</span>{{ $t('lang.gles.workflow.execFrequency') }}
      </el-divider>
      <el-radio-group :value="value" @input="$emit('update:value', $event)">
        <el-radio
          v-for="item in execFreqList"
          :key="item.value"
          :label="item.value"
          :disabled="mode === 'detail'"
        >
          {{ item.label }}
        </el-radio>
      </el-radio-group>
      <!-- 执行频率为每周时，选择周 -->
      <div v-if="value === 2" class="week-wrapper">
        <span class="label">{{ $t('lang.gles.workflow.selectWeek') }}：</span>
        <el-checkbox
          :value="checkAll"
          class="check-all"
          :indeterminate="isIndeterminate"
          :disabled="mode === 'detail'"
          @input="handleCheckAllChange"
        >
          {{ $t('lang.gles.common.selectAll') }}
        </el-checkbox>
        <el-checkbox-group
          v-model="selectedWeeks"
          :disabled="mode === 'detail'"
          @change="handleCheckedWeeksChange"
        >
          <el-checkbox
            v-for="d in week"
            :key="d.value"
            :label="d.value"
            :value="d.value"
          >
            {{ d.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <!-- 执行频率选择 月 时 -->
      <template v-if="value === 3">
        <span
          style="margin-right: 20px"
        >{{ $t('lang.gles.workflow.selectMonth') }}:</span>
        <el-select
          v-model="selectedMonths"
          multiple
          collapse-tags
          :disabled="mode === 'detail'"
          @change="handleMonthChange"
        >
          <el-option
            v-for="m in monthsList"
            :key="m.value"
            :label="m.label"
            :value="m.value"
            @click.native="handleMonthOptionClick(m.value)"
          />
        </el-select>
        <span style="margin-right: 20px; margin-left: 20px">{{ $t('lang.gles.workflow.selectDay') }}:</span>
        <el-select
          v-model="selectedDays"
          multiple
          collapse-tags
          :disabled="mode === 'detail'"
          @change="handleDaysChange"
        >
          <el-option
            v-for="d in daysList"
            :key="d.value"
            :label="d.label"
            :value="d.value"
            @click.native="handleDayOptionClick(d.value)"
          />
        </el-select>
      </template>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { t } from '@/libs_sz/locale'

export default {
  name: 'ExecFrequency',
  props: {
    value: {
      type: Number,
      default: 1
    },
    /**
     * 选择星期
     */
    weeks: {
      type: Array,
      default: () => []
    },
    /**
     * 选择月份
     */
    months: {
      type: Array,
      default: () => []
    },
    /**
     * 选择日期
     */
    days: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      checkAll: false,
      checkedDay: [],
      selectedWeeks: [],
      selectedMonths: [],
      selectedDays: [],
      isIndeterminate: false,
      week: [
        {
          label: t('lang.gles.common.monday'),
          value: 2
        },
        {
          label: t('lang.gles.common.tuesday'),
          value: 3
        },
        {
          label: t('lang.gles.common.wednesday'),
          value: 4
        },
        {
          label: t('lang.gles.common.thursday'),
          value: 5
        },
        {
          label: t('lang.gles.common.friday'),
          value: 6
        },
        {
          label: t('lang.gles.common.saturday'),
          value: 7
        },
        {
          label: t('lang.gles.common.sunday'),
          value: 1
        }
      ],
      monthsList: [
        {
          label: t('lang.gles.common.selectAll'),
          value: -1
        },
        {
          label: t('lang.gles.common.January'),
          value: 1
        },
        {
          label: t('lang.gles.common.February'),
          value: 2
        },
        {
          label: t('lang.gles.common.March'),
          value: 3
        },
        {
          label: t('lang.gles.common.April'),
          value: 4
        },
        {
          label: t('lang.gles.common.May'),
          value: 5
        },
        {
          label: t('lang.gles.common.June'),
          value: 6
        },
        {
          label: t('lang.gles.common.July'),
          value: 7
        },
        {
          label: t('lang.gles.common.August'),
          value: 8
        },
        {
          label: t('lang.gles.common.September'),
          value: 9
        },
        {
          label: t('lang.gles.common.October'),
          value: 10
        },
        {
          label: t('lang.gles.common.November'),
          value: 11
        },
        {
          label: t('lang.gles.common.December'),
          value: 12
        }
      ],
      daysList: []
    }
  },
  computed: {
    ...mapState('workflow', ['execFreqList', 'planTaskTypeList', 'execActionList'])
  },
  watch: {
    weeks() {
      this.initSelectedWeeks()
    },
    months() {
      this.initSelectedMonths()
    },
    days() {
      this.initSelectedDays()
    }
  },
  mounted() {
    this.initSelectedWeeks()
    this.initSelectedMonths()
    this.initSelectedDays()
    this.initDaysList()
    if (this.mode === 'edit') {
      this.isIndeterminate = true
    }
  },
  methods: {
    initSelectedWeeks() {
      this.selectedWeeks = this._.cloneDeep(this.weeks)
    },
    initSelectedMonths() {
      if (this.months.includes(-1) && this.months?.length === 1) {
        this.selectedMonths = this.monthsList.map(t => t.value)
      } else {
        this.selectedMonths = this._.cloneDeep(this.months)
      }
    },
    initSelectedDays() {
      if (this.days.includes(-1) && this.days?.length === 1) {
        this.selectedDays = this.daysList.map(t => t.value).filter(t => t !== 0)
      } else {
        this.selectedDays = this._.cloneDeep(this.days)
      }
    },
    /**
     * 选择星期 全选
     * @param {*} val
     */
    handleCheckAllChange(val) {
      this.checkedDay = val
      this.isIndeterminate = false
      this.checkAll = val
      const checkedList = val ? this.week.map((t) => t.value) : []
      this.selectedWeeks = checkedList
      this.$emit('update:weeks', checkedList)
    },
    /**
     * 选择星期
     * @param {*} value
     */
    handleCheckedWeeksChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.week.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.week.length
      this.$emit('update:weeks', value)
      this.selectedWeeks = value
    },
    /**
     * 月 change事件
     * @param {*} val
     */
    handleMonthChange(val) {
      this.$emit('update:months', val)
    },
    /**
     * 月-全选操作
     * @param {*} value
     */
    handleMonthOptionClick(value) {
      switch (value) {
        case -1:
          if (!this.selectedMonths.includes(-1)) {
            this.selectedMonths = []
            this.$emit('update:months', [])
          } else {
            this.selectedMonths = this.monthsList.map(t => t.value)
            this.$emit('update:months', [-1])
          }
          break
        default:
          if (this.selectedMonths.length === this.monthsList?.length - 1 && !this.selectedMonths.includes(-1)) {
            this.selectedMonths = this.monthsList.map(t => t.value)
          } else {
            this.selectedMonths = this.selectedMonths.filter(t => t !== -1)
          }
          break
      }
    },
    /**
     * 选择日
     * @param {*} val
     */
    handleDaysChange(val) {
      this.$emit('update:days', val)
    },
    /**
     * 日-全选
     * 日-每月最后一天
     * 每月最后一天合其他天互斥
     * @param {*} value
     */
    handleDayOptionClick(value) {
      switch (value) {
        case -1:
          if (!this.selectedDays.includes(-1)) {
            this.selectedDays = []
            this.$emit('update:days', [])
          } else {
            this.selectedDays = this.daysList.map(t => t.value).filter(t => t !== 0)
            this.$emit('update:days', [-1])
          }
          break
        case 0:
          if (this.selectedDays.includes(0)) {
            this.selectedDays = [0]
            this.$emit('update:days', [0])
          }
          break
        default:
          if (this.selectedDays.length < this.daysList?.length - 1) {
            this.selectedDays = this.selectedDays.filter(t => ![0, -1].includes(t))
          }
          break
      }
    },
    /**
     * 日 下拉选列表
     */
    initDaysList() {
      let maxDay = 1
      // 全选
      const list = [{
        label: this.$t('lang.gles.common.selectAll'),
        value: -1
      }]
      while (maxDay <= 31) {
        const obj = {
          label: `${maxDay}${this.$t('lang.gles.common.day')}`,
          value: maxDay
        }
        maxDay++
        list.push(obj)
      }
      // 最后一天
      list.push({
        label: this.$t('lang.gles.common.lastDayOfEachMonth'),
        value: 0
      })
      this.daysList = list
    }
  }
}
</script>
<style lang="scss" scoped>
.schedule-wrapper {
  .required-icon {
    margin-right: 4px;
    color: #f56c6c;
    font-size: 10px;
  }
  .el-radio-group {
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .week-wrapper {
    display: flex;
    .check-all {
      margin-right: 10px;
    }
  }

  .el-divider__text {
    font-size: 13px !important;
  }
}
</style>
