export default {
  data() {
    return {
      // 更多查询
      moreQueryVisible: false,
      // 列设置
      columnConfigVisible: false,
      columnConfigList: [],
      pageData: {
        currentPage: 1,
        pageSize: 10
      },
      tableExtendConfig: {
        sortNum: true,
        checkBox: false,
        columnMinWidth: 12 * 8, // 所有列表列头名称显示规范： 最多显示6个汉字，12个字符，超出...，鼠标hover显示全称
        operateWidth: '120px',
        operate: [
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit')
          },
          {
            event: 'delItem',
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            label: this.$t('lang.gles.common.delete'),
            customClass: 'danger'
          }
        ]
      },
      columnItem: {}
    }
  },
  provide() {
    return {
      handleReset: this.handleReset
    }
  },
  watch: {
    defaultTableItem: {
      handler() {
        this.columnConfigList = this._.cloneDeep(this.defaultTableItem)
      },
      immediate: true
    },
    formModel: {
      handler() {
        this.$nextTick(() => {
          this.$refs.myQueryView?.$refs?.myForm?.$refs?.moreQueryData?.changeFormValue(
            this.formModel ?? {}
          )
        })
      },
      deep: true
    }
  },
  methods: {
    /**
     * 获取字典label值
     * @param {*} list 字典列表
     * @param {*} key 字典key
     */
    getDictLabel(list = [], value) {
      return list?.find((t) => t.value === value)?.label ?? value
    },
    /**
     * 表单项改变事件
     */
    onFormChange(val, formItem, changeFormValue, formModel) {
      if (typeof val === 'string') {
        this.$set(this.formModel, formItem.name, this._.trim(val))
      } else {
        this.$set(this.formModel, formItem.name, val)
      }
    },
    /**
     * 更多查询重置
     */
    handleReset(data) {
      this.formModel = data
    },
    linefeed(h, { column, index }) {
      // column,index都是el-table中自带的
      // const number = column.label.length // 表头字数
      const size = 14 // 字体尺寸
      column.width = 12 * size // 计算宽度
      return h('div', { class: 'table-head', style: { width: '100%' } }, [
        column.label
      ])
    },
    /**
     * 页码改变
     * @param {*} pageData
     */
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.$refs?.myQueryView?.histSearch()
    },
    /**
     * 切换分页之后重置currentPage
     */
    resetPageData() {
      this.pageData = {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      }
    }
  }
}
