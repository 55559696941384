<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="planTaskCode"
      :value.sync="baseFormModel.planTaskCode"
      :placeholder="$t('lang.gles.workflow.pleaseInputPlanTaskCode')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom('add')">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @runRecordItem="handleRunRecord"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.planTaskCode }}
          </span>
        </template>
        <template #planTaskStatusSlot="{ row }">
          <span class="plan-task-status">
            <i class="ui-dot-process" :style="{'--dotColor': getStatusColor(row.state)}" />
            {{ getDictLabel(planTaskStateList, row.state) }}
          </span>
        </template>
        <template #runOptSlot="{ row }">
          <!-- :disabled="!isRunEnabled(row)" -->
          <el-button
            type="text"
            class="mr10 ml0 f12"
            @click="handleRunClick({ row })"
          >
            {{
              row.state === 1
                ? $t('lang.gles.workflow.terminated')
                : $t('lang.gles.workflow.run')
            }}
          </el-button>
        </template>
      </m-table>
    </el-card>
    <!-- 运行记录 -->
    <record-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :row-detail="row"
    />
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :row-detail="row"
      :mode="mode"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import dayjs from 'dayjs'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'
import RecordDialog from './record-dialog'
import DetailDialog from './detail'

export default {
  name: 'PlanTaskList',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    RecordDialog,
    DetailDialog
  },
  mixins: [listMixins, commonMixins],
  data() {
    return {
      tableData: [],
      row: {},
      baseFormModel: {},
      formModel: {},
      dialogVisible: false,
      detailDialogVisible: false,
      recordTableData: [],
      tableExtendConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '200px',
        operate: [
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit'),
            getDisabled: this.isEditEnabled
          },
          {
            event: 'runItem',
            label: this.$t('lang.gles.workflow.run'),
            slotName: 'runOptSlot'
          },
          {
            event: 'runRecordItem',
            label: this.$t('lang.gles.workflow.runRecords')
          },
          {
            event: 'delItem',
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            label: this.$t('lang.gles.common.delete'),
            customClass: 'danger'
          }
        ]
      },
      execActionList: []
    }
  },
  computed: {
    ...mapState('workflow', ['planTaskTypeList', 'planTaskStateList']),
    moreQueryData() {
      return getMoreQueryFormData(this)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.initSelectList()
    this.doSearch(this.formModel)
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      if (!this.planTaskTypeList?.length) {
        this.$store.dispatch('workflow/getPlanTaskTypeList')
      }
      if (!this.planTaskStateList?.length) {
        this.$store.dispatch('workflow/getPlanTaskStateList')
      }
      this.getExecActionList()
    },
    /**
     * 获取执行动作列表
     */
    async getExecActionList() {
      const { data, code } = await this.$httpService(
        this.$apiStore.strategyManage('getTallyDictList')
      )
      if (code) return
      this.execActionList = (data || []).map((item) => {
        return {
          label: item.strategyName,
          value: item.id
        }
      })
    },
    /**
     * 任务状态 class
     * @param {*} state
     */
    getStatusColor(state) {
      return state === 1 ? '#409eff' : state === 2 ? '#00c07b' : '#e72f38'
    },
    /**
     * 新增
     * @param {*} mode
     */
    updateCom(mode) {
      this.$emit('updateCom', {
        currentCom: 'PlanTaskAdd',
        mode,
        row: {}
      })
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data })
    },
    /**
     * 接口查询
     */
    async doSearch(params, api = 'getPlanTaskList') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.workflow(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 1.只有任务状态=已完成 或 任务状态=已终止的记录才显示运行按钮
     * 2.运行时需要判断计划任务中的时间是否超过当前时间（单次执行时，设定的日期是否大于当前日期；循环执行时，持续时间中的结束时间是否大于当前时间）
     * @param {*} row
     * @param {*} showMsg
     */
    isRunEnabled(row, showMsg) {
      // 已终止 已完成
      if (row.state === 2 || row.state === 3) {
        const nowStr = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
        // 重复执行
        if (row.execWay === 1) {
          if (row.endTime && row.endTime <= nowStr) {
            if (showMsg) {
              this.$message.error(
                this.$t('lang.gles.workflow.planTask.checkMsg9')
              )
            }
            return false
          }
        } else {
          if (row.execTime <= nowStr) {
            if (showMsg) {
              this.$message.error(
                this.$t('lang.gles.workflow.planTask.checkMsg10')
              )
            }
            return false
          }
        }
      }
      return true
    },
    /**
     * 运行中的任务不可编辑
     * @param {*} row
     */
    isEditEnabled(row) {
      return row.state === 1
    },
    /**
     * 查看详情
     * @param {*} row
     */
    handleDetail(row) {
      this.row = row
      this.mode = 'detail'
      this.detailDialogVisible = true
    },
    // 编辑模板
    editItem({ row }) {
      // 运行中 不可编辑
      if (row.state === 1) {
        this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg11'))
        return
      }
      this.$emit('updateCom', {
        currentCom: 'PlanTaskAdd',
        mode: 'edit',
        row
      })
    },
    /**
     * 查看运行记录
     * @param {*} param
     */
    async handleRunRecord({ row }) {
      this.row = row
      this.dialogVisible = true
    },
    /**
     * 终止
     * 运行
     * 1、单次执行时，设定的日期是否大于当前日期
     * 2、循环执行时，持续时间中的结束时间是否大于当前时间
     */
    async handleRunClick({ row }) {
      if (!this.isRunEnabled(row, true)) return
      // 运行中，执行终止操作
      const action = row.state === 1 ? 1 : 0
      const { code } = await this.$httpService(
        this.$apiStore.workflow('operatePlanTaskById'),
        {
          id: row.id,
          action
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.workflow.operateSuccess'))
      row.state = row.state === 1 ? 3 : 1
    },
    /**
     * 删除模版
     * 1、二次确认
     * 2、若容器类型无存在的容器档案，则删除成功，否则失败
     */
    async delItem({ row }) {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('operatePlanTaskById'),
        {
          id: row.id,
          action: 2
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData?.length < 2) {
        this.pageData.currentPage = 1
      }
      this.doSearch(this.baseFormModel)
    }
  }
}
</script>
<style lang="scss" scoped>
.plan-task-status {
  .ui-dot-process {
    background: var(--dotColor);
    &:after {
      border-color: var(--dotColor);
    }
  }
}
</style>
