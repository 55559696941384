import mapVerify from '@/utils/formValidate'

// 获取更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 计划任务编码
    {
      name: 'planTaskCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.planTaskCode')
    },
    // 计划任务名称
    {
      name: 'planTaskName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.planTaskName')
    },
    // 任务类型
    {
      name: 'planTaskType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.planTaskType'),
      data: that.planTaskTypeList
    },
    // 执行动作
    {
      name: 'execAction',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.execAction'),
      filterable: true,
      data: that.execActionList
    },
    // 任务状态
    {
      name: 'state',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.taskStatus'),
      filterable: true,
      data: that.planTaskStateList
    }
  ]
  return moreQueryData
}
// 列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 计划任务编码
    {
      prop: 'planTaskCode',
      label: `${that.$t('lang.gles.workflow.planTaskCode')}`,
      isShow: true,
      sortable: true,
      slotName: 'clickSlot'
    },
    // 计划任务名称
    {
      prop: 'planTaskName',
      label: that.$t('lang.gles.workflow.planTaskName'),
      isShow: true
    },
    // 任务类型
    {
      prop: 'planTaskType',
      label: `${that.$t('lang.gles.workflow.planTaskType')}`,
      isShow: true,
      formatter(row, column) {
        return that.getDictLabel(that.planTaskTypeList, row[column])
      }
    },
    // 执行动作
    {
      prop: 'execAction',
      label: `${that.$t('lang.gles.workflow.execAction')}`,
      isShow: true,
      formatter(row, column) {
        return that.getDictLabel(that.execActionList, row[column])
      }
    },
    // 任务状态
    {
      prop: 'status',
      label: `${that.$t('lang.gles.workflow.taskStatus')}`,
      isShow: true,
      slotName: 'planTaskStatusSlot'
    },
    // 备注
    {
      prop: 'remark',
      label: `${that.$t('lang.gles.workflow.planTaskRemark')}`,
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 基本信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 计划任务编码
    {
      name: 'planTaskCode',
      value: row.planTaskCode || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.planTaskCode'),
      rules: mapVerify(['required', 'inputCode']),
      isShow: true,
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon',
      disabled: that.mode === 'detail'
    },
    // 计划任务名称
    {
      name: 'planTaskName',
      value: row.planTaskName || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.planTaskName'),
      rules: mapVerify(['required', 'inputLen200']),
      isShow: true,
      disabled: that.mode === 'detail'
    },
    // 任务类型
    {
      name: 'planTaskType',
      value: row.planTaskType ?? '',
      span: 8,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.planTaskType'),
      rules: mapVerify(['required']),
      isShow: true,
      data: that.planTaskTypeList,
      mchange: that.handlePlanTaskTypeChange,
      disabled: that.mode === 'detail'
    },
    // 执行动作
    {
      name: 'execAction',
      value: row.execAction ?? '',
      span: 8,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.execAction'),
      rules: mapVerify(['required']),
      isShow: true,
      data: that.execActionList,
      disabled: that.mode === 'detail'
    },
    // 备注
    {
      name: 'remarks',
      value: row.remarks ?? '',
      span: 8,
      component: 'elInput',
      type: 'textarea',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.planTaskRemark'),
      rules: mapVerify(['inputLen500']),
      isShow: true,
      disabled: that.mode === 'detail'
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}

// 运行记录
export const getRecordSearchTableItem = (that) => {
  const searchTableItem = [
    // 计划任务编码
    {
      prop: 'planTaskCode',
      label: `${that.$t('lang.gles.workflow.planTaskCode')}`,
      isShow: true
    },
    // 计划任务名称
    {
      prop: 'planTaskName',
      label: that.$t('lang.gles.workflow.planTaskName'),
      isShow: true
    },
    // 开始时间
    {
      prop: 'beginTime',
      label: `${that.$t('lang.gles.common.startTime')}`,
      isShow: true
    },
    // 运行结果
    {
      prop: 'state',
      label: `${that.$t('lang.gles.workflow.execStatus')}`,
      isShow: true,
      slotName: 'planTaskStatusSlot'
    },
    // 任务状态
    {
      prop: 'failReason',
      label: `${that.$t('lang.gles.workflow.planTaskFailReason')}`,
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}
