<template>
  <el-popover placement="bottom" width="160" trigger="click">
    <div class="column-config-wrapper">
      <div class="check-all">
        <el-checkbox v-model="checkedAll" @change="toggleAll">
          列展示
        </el-checkbox>
      </div>
      <ul class="column-list">
        <li v-for="(item, i) in columList" :key="i" class="column-item">
          <el-checkbox v-model="item.isShow" @change="onChange(item)">
            {{ item.label }}
          </el-checkbox>
        </li>
      </ul>
    </div>
    <el-tooltip
      slot="reference"
      class="item"
      effect="dark"
      content="列设置"
      placement="top"
    >
      <i class="el-icon-setting" />
    </el-tooltip>
  </el-popover>
</template>
<script>
export default {
  name: 'ColumnConfig',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columList: [],
      checkedAll: true
    }
  },
  watch: {
    list: {
      handler: function() {
        this.columList = this._.cloneDeep(this.list)
      },
      deep: true
    }
  },
  created() {
    this.columList = this._.cloneDeep(this.list)
  },
  methods: {
    toggleAll() {
      // eslint-disable-next-line no-return-assign
      this.columList.forEach(item => item.isShow = this.checkedAll)
      this.$emit('update:list', this._.cloneDeep(this.columList))
    },
    onChange() {
      const checkedList = this.columList.filter(item => item.isShow)
      this.checkedAll = checkedList?.length === this.columList?.length
      this.$emit('update:list', this._.cloneDeep(this.columList))
    }
  }
}
</script>
<style lang="scss">
.column-config-wrapper {
  margin: -12px;
  .check-all {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
  .column-list {
    padding: 10px 0 10px 20px;
    border-top: 1px solid #eee;
  }
}
</style>
