<template>
  <el-card class="header-path-card">
    <div class="header-path">
      <span v-for="(item, i) in paths" :key="i" class="header-path-content">{{
        item
      }}</span>
    </div>
  </el-card>
</template>
<script>
export default {
  name: 'HeaderPath',
  props: {
    paths: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss">
  .header-path-card {
    margin-bottom: 6px;
    .el-card__body {
      padding: 10px 20px;
    }
    .header-path {
      position: relative;
      &:before {
        position: absolute;
        left: -10px;
        top: 0;
        content: "";
        width: 4px;
        height: 100%;
        background: #409EFF;
        border-radius: 4px;
      }
    }
  }
</style>
