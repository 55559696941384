<template>
  <el-drawer
    :visible="visible"
    size="80%"
    custom-class="gles-drawer"
    :with-header="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="drawer-wrapper">
      <div class="drawer-operate-btn">
        <el-button
          type="primary"
          :disabled="isEditDisabled()"
          @click="handleEdit"
        >
          {{ $t('lang.gles.common.edit') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="!isRunEnabled"
          @click="handleRunClick"
        >
          {{
            rowDetail.state === 1
              ? $t('lang.gles.workflow.terminated')
              : $t('lang.gles.workflow.run')
          }}
        </el-button>
        <el-button
          type="danger"
          :disabled="formModel.templateStatus === 0"
          @click="handleDelete"
        >
          {{ $t('lang.gles.common.delete') }}
        </el-button>
        <i class="el-icon-close" @click="$emit('update:visible', false)" />
      </div>
      <plan-task-add mode="detail" :row-detail="rowDetail" />
    </div>
  </el-drawer>
</template>
<script>
import addMixins from '@/mixins/addMixins'
import { getEditBaseFormData } from '../data'
import { mapState } from 'vuex'
import PlanTaskAdd from './add'
import dayjs from 'dayjs'

export default {
  name: 'PlanTaskDetail',
  components: {
    PlanTaskAdd
  },
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'detail'
    },
    /**
     * 可放子容器 默认是
     */
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      isNeedEndTime: false, // 持续时间  是否设置  结束时间
      formModel: {
        execWay: 1,
        execTime: '',
        execFrequency: 1,
        weeks: [],
        months: [],
        days: [],
        execInterval: 1,
        onceHms: '', // HH:mm:ss
        intervals: 1, // 重复间隔数
        unit: 'h', // 重复执行间隔数单位 h m s
        startHms: '', // 重复执行间隔 开始时间  HH:mm:ss
        endHms: '', // 重复执行间隔 结束时间 HH:mm:ss
        startTime: '',
        endTime: ''
      },
      // 任务类型列表
      execActionList: [],
      usedExecActionList: []
    }
  },
  computed: {
    ...mapState('workflow', ['planTaskTypeList']),
    baseFormData() {
      const row = this.rowDetail || { ...this.formModel }
      return getEditBaseFormData(this, { ...row })
    }
  },
  methods: {
    /**
     * 编辑
     */
    handleEdit() {
      this.$EventBus.$emit('page:updateCom', {
        currentCom: 'PlanTaskAdd',
        mode: 'edit',
        row: this.rowDetail
      })
    },
    /**
     * 终止
     * 运行
     * 1、单次执行时，设定的日期是否大于当前日期
     * 2、循环执行时，持续时间中的结束时间是否大于当前时间
     */
    async handleRunClick() {
      const row = this.rowDetail
      if (!this.isRunEnabled(row, true)) return
      // 运行中，执行终止操作
      const action = row.state === 1 ? 1 : 0
      const { code } = await this.$httpService(
        this.$apiStore.workflow('operatePlanTaskById'),
        {
          id: row.id,
          action
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.workflow.operateSuccess'))
      row.state = row.state === 1 ? 3 : 1
      row.stateName =
        row.state === 3
          ? this.$t('lang.gles.workflow.planTaskStateSuspend')
          : this.$t('lang.gles.workflow.planTaskStateRunning')
    },
    /**
     * 1.只有任务状态=已完成 或 任务状态=已终止的记录才显示运行按钮
     * 2.运行时需要判断计划任务中的时间是否超过当前时间（单次执行时，设定的日期是否大于当前日期；循环执行时，持续时间中的结束时间是否大于当前时间）
     * @param {*} row
     * @param {*} showMsg
     */
    isRunEnabled(row, showMsg) {
      // 已终止 已完成
      if (row.state === 2 || row.state === 3) {
        const nowStr = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
        // 重复执行
        if (row.execWay === 1) {
          if (row.endTime && row.endTime <= nowStr) {
            if (showMsg) {
              this.$message.error(
                this.$t('lang.gles.workflow.planTask.checkMsg9')
              )
            }
            return false
          }
        } else {
          if (row.execTime <= nowStr) {
            if (showMsg) {
              this.$message.error(
                this.$t('lang.gles.workflow.planTask.checkMsg10')
              )
            }
            return false
          }
        }
      }
      return true
    },
    /**
     * 运行中任务不可编辑
     */
    isEditDisabled() {
      return this.rowDetail.state === 1
    },
    /**
     * 删除
     */
    handleDelete() {
      // 二次确认
      const confirmMsg = this.$t('lang.gles.common.deleteConfirmMsg0')
      if (confirmMsg) {
        this.$confirm(confirmMsg, '', {
          confirmButtonText: this.$t('lang.gles.common.confirm'),
          cancelButtonText: this.$t('lang.gles.common.cancel'),
          type: 'warning'
        }).then(() => {
          this.deletePlanTask()
        })
        return
      }
    },
    /**
     * 接口删除
     */
    async deletePlanTask() {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('operatePlanTaskById'),
        {
          id: this.rowDetail.id,
          action: 3
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      this.$emit('update:visible', false)
      this.$parent.doSearch()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-drawer__header {
  margin-bottom: -30px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>
