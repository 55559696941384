export default {
  data() {
    return {
      loading: false,
      factoryList: [],
      workshopList: [],
      warehouseList: [],
      warehouseAreaList: [],
      areaList: [],
      productionLineList: [],
      deviceList: [],
      factoryPositionList: [],
      goodsPositionList: [],
      fixGoodsPositionList: [],
      deviceGoodsPositionList: [],
      moveGoodsPositionList: [],
      fixedGoodsPositionList: [],
      containerCodeList: [],
      containerTypeList: [],
      materialCodeList: [],
      materialList: [],
      turnoverContainerCodeList: [],
      // 未被容器使用的库位列表
      noUsefactoryPositionList: []
    }
  },
  activated() {
    this.initSelectList && this.initSelectList()
  },
  methods: {
    /**
     * 获取字典label值
     * @param {*} list 字典列表
     * @param {*} key 字典key
     */
    getDictLabel(list = [], value) {
      return list?.find(t => t.value === value)?.label ?? value
    },
    /**
     * 重置工厂
     */
    resetFactory(model = {}) {
      if (model.factoryId) {
        model.factoryId = ''
        model.factoryName = ''
      }
    },
    /**
     * 重置车间
     */
    resetWorkshop(model = {}, queryType) {
      if (model.workshopId) {
        model.workshopId = ''
        model.workshopName = ''
      }
      if (queryType === 'cascade' && !model.factoryId) {
        queryType = null
      }
      switch (queryType) {
        case 'cascade':
          this.getWorkshopList({ factoryId: model.factoryId })
          break
        case 'all':
          this.getWorkshopList()
          break
        default:
          this.workshopList = []
          break
      }
    },
    /**
     * 重置仓库
     */
    resetWarehouse(model = {}, queryType) {
      if (model.warehouseId) {
        model.warehouseId = ''
        model.warehouseName = ''
      }
      if (queryType === 'cascade' && !model.factoryId) {
        queryType = null
      }
      switch (queryType) {
        case 'cascade':
          this.getWarehouseList({ factoryId: model.factoryId })
          break
        case 'all':
          this.getWarehouseList()
          break
        default:
          this.warehouseList = []
          break
      }
    },
    /**
     * 重置库区
     */
    resetWarehouseArea(model = {}, queryType) {
      if (model.warehouseAreaId) {
        model.warehouseAreaId = ''
        model.warehouseAreaName = ''
      }
      if (queryType === 'cascade' && !model.warehouseId && !model.workshopId) {
        queryType = null
      }
      switch (queryType) {
        case 'cascade':
          if (model.workshopId) {
            this.getWarehouseAreaList({ workshopId: model.workshopId })
          }
          if (model.warehouseId) {
            this.getWarehouseAreaList({ warehouseId: model.warehouseId })
          }
          break
        case 'all':
          this.getWarehouseAreaList()
          break
        default:
          this.warehouseAreaList = []
          break
      }
    },
    /**
     * 重置区域
     * @param {Object} model model对象
     * @param {String} queryType
     */
    resetArea(model = {}, queryType) {
      if (model.areaId) {
        model.areaId = ''
        model.areaName = ''
      }
      if (queryType === 'cascade' && !model.workshopId) {
        queryType = null
      }
      switch (queryType) {
        case 'cascade':
          this.getAreaList({ workshopId: model.workshopId })
          break
        case 'all':
          this.getAreaList()
          break
        default:
          this.areaList = []
          break
      }
    },
    /**
     * 重置产线
     * @param {Object} model model对象
     * @param {String} queryType
     */
    resetProductionLine(model = {}, queryType) {
      if (model.productionLineId) {
        model.productionLineId = ''
        model.productionLineName = ''
      }
      if (queryType === 'cascade' && !model.areaId) {
        queryType = null
      }
      switch (queryType) {
        case 'cascade':
          this.getProductionLineList({ areaId: model.areaId })
          break
        case 'all':
          this.getProductionLineList()
          break
        default:
          this.productionLineList = []
          break
      }
    },
    /**
     * 重置设备
     * @param {Object} model model对象
     * @param {String} queryType
     */
    resetDevice(model = {}, queryType) {
      if (model.deviceId) {
        model.deviceId = ''
        model.deviceName = ''
      }
      if (queryType === 'cascade' && !model.productionLineId) {
        queryType = null
      }
      switch (queryType) {
        case 'cascade':
          this.getDeviceList({ productionLineId: model.productionLineId })
          break
        case 'all':
          this.getDeviceList()
          break
        default:
          this.deviceList = []
          break
      }
    },
    /**
     * 获取工厂列表
     */
    async getFactoryList(params = {}) {
      this.getSelectOptions('getFactoryDictList', params).then(
        (list) => (this.factoryList = list)
      )
    },
    /**
     * 获取车间列表
     */
    getWorkshopList(params) {
      this.getSelectOptions('getWorkshopDictList', params).then(
        (list) => (this.workshopList = list)
      )
    },
    /**
     * 获取仓库列表
     */
    getWarehouseList(params) {
      this.getSelectOptions('getWarehouseDictList', params).then(
        (list) => (this.warehouseList = list)
      )
    },
    /**
     * 获取库区列表
     */
    getWarehouseAreaList(params) {
      this.getSelectOptions('getWarehouseAreaDictList', params).then(
        (list) => (this.warehouseAreaList = list)
      )
    },
    /**
     * 获取区域列表
     */
    getAreaList(params) {
      this.getSelectOptions('getAreaDictList', params).then(
        (list) => (this.areaList = list)
      )
    },
    /**
     * 获取产线列表
     */
    getProductionLineList(params) {
      this.getSelectOptions('getProductionLineDictList', params).then(
        (list) => (this.productionLineList = list)
      )
    },
    /**
     * 获取设备列表
     */
    getDeviceList(params) {
      this.getSelectOptions('getDeviceDictList', params).then(
        (list) => (this.deviceList = list)
      )
    },
    /**
     * 获取设备货位列表
     */
    getDeviceGoodsPositionList(params) {
      this.$httpService(
        this.$apiStore.base('getGoodsPositionListByType'),
        params
      ).then(({ data }) => {
        this.deviceGoodsPositionList = data.map((item) => {
          return { label: item.code, value: item.id }
        })
      })
    },
    /**
     * 获取库位列表
     */
    getFactoryPositionList(params) {
      this.getSelectOptions('getFactoryPositionDictList', params).then(
        (list) => {
          this.factoryPositionList = list
          this.$store.dispatch('moreData/addFpCodeList', list)
        }
      )
    },
    /**
     * 获取未使用的库位列表
     */
    getNoUseFactoryPositionList(params) {
      this.getSelectOptions('getNoUseFactoryPositionDictList', params).then(
        (list) => {
          this.noUsefactoryPositionList = list
          let usedList =
          this.containerArchivesDetailList.map((t) => t.factoryPositionId) ?? []
          usedList = usedList.concat(this.containerArchivesList.map((t) => t.factoryPositionId) ?? [])
          const usableList = this._.cloneDeep(this.noUsefactoryPositionList)
          usableList.forEach((t) => {
            t.disabled = usedList.includes(t.value)
          })
          this.$store.dispatch('moreData/addUsableFactoryPositionList', usableList)
        }
      )
    },
    /**
     * 获取所有货位编码列表
     */
    getGoodsPositionList(params) {
      this.$httpService(
        this.$apiStore.base('getGoodsPositionListByType'),
        params
      ).then(
        ({ data }) =>
          (this.goodsPositionList = data.map((item) => {
            return { label: item.code, value: item.id }
          }))
      )
    },
    /**
     * 获取移动货位编码列表
     */
    getMoveGoodsPositionList(params) {
      this.$httpService(
        this.$apiStore.base('getGoodsPositionListByType'),
        params
      ).then(({ data }) => {
        this.moveGoodsPositionList = data.map((item) => {
          return { label: item.code, value: item.id }
        })
      })
    },
    /**
     * 获取固定货位编码列表
     */
    getFixedGoodsPositionList(params) {
      this.$httpService(
        this.$apiStore.base('getGoodsPositionListByType'),
        params
      ).then(({ data }) => {
        this.fixedGoodsPositionList = data.map((item) => {
          return { label: item.code, value: item.id }
        })
      })
    },
    /**
     * 获取固定货位列表
     */
    getFixGoodsPositionList(params) {
      this.getSelectOptions(
        'getFixGoodsPositionByWarehouseAreaId',
        params
      ).then((list) => (this.fixGoodsPositionList = list))
    },

    /**
     * 获取容器类型列表
     * @param {*} params
     */
    async getContainerTypeList(params = {}) {
      await this.getSelectOptions('getContainerTypeDictList', params).then(
        (list) => (this.containerTypeList = list)
      )
    },
    /**
     * 获取容器列表
     * @param {*} params
     */
    getContainerCodeList(params = {}) {
      this.getSelectOptions('getContainerDictList', params).then(
        (list) => (this.containerCodeList = list)
      )
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.materialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.materialList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    /**
     * 获取模板列表
     * @param {*} params
     */
    async getExecTemplateList(params = {}) {
      await this.getSelectOptions('getExecTemplateList', params).then(
        (list) => (this.execTemplateList = list)
      )
    },
    /**
     * 获取数据列表
     * @param {*} apiName 接口名
     * @param {*} params 请求参数
     * @returns 选项列表
     */
    getSelectOptions(apiName, params) {
      const that = this
      return new Promise((resolve, reject) => {
        this.$httpService(this.$apiStore.base(apiName), params)
          .then(({ data, code }) => {
            if (code !== 0) {
              reject()
              return
            }
            const recordList = data || []
            const list = recordList.map((item) => {
              if (item.containerTypeCode) {
                return {
                  label: that.$t(item.containerTypeName),
                  value: item.id,
                  containerTypeCode: item.containerTypeCode,
                  containerTypePattern: item.containerTypePattern,
                  containerTypePatternType: item.containerTypePatternType
                }
              }
              if (item.containerArchivesCode) {
                return {
                  label: item.containerArchivesCode,
                  value: item.id
                }
              }
              if (item.factoryPositionCode) {
                return {
                  label: item.factoryPositionName,
                  value: item.id,
                  factoryPositionCode: item.factoryPositionCode,
                  factoryPositionType: item.factoryPositionType,
                  freezeFlag: item.freezeFlag
                }
              }
              // 机器人执行模板
              if (item.templateCode) {
                return {
                  label: that.$t(item.templateName),
                  value: item.templateCode,
                  robotType: item.robotType
                }
              }
              return {
                label: that.$t(item.name || item.code),
                value: item.id
              }
            })
            resolve(list)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
