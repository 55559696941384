<template>
  <div class="basic-data-add">
    <header-path v-if="mode !== 'detail'" :paths="headerPaths" />
    <el-card class="add-card">
      <h3 class="title">
        {{ $t('lang.gles.common.basicInfo') }}
      </h3>
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
        :button="button"
      >
        <template #inputCodeTipIcon>
          <el-tooltip
            popper-class="workshop_code_tip-icon"
            effect="dark"
            :content="$t(autoGeneCodeTip)"
            placement="right"
          >
            <i class="el-icon-tip el-icon-question" />
          </el-tooltip>
        </template>
      </m-form>
    </el-card>
    <el-card class="add-card" style="margin-top: 10px">
      <h3 class="title">
        {{ $t('lang.gles.workflow.planSchedule') }}
      </h3>
      <!-- 执行方式 -->
      <exec-type
        :value.sync="formModel.execWay"
        :exec-time.sync="formModel.execTime"
        :mode="mode"
      />
      <!-- 执行频率 -->
      <exec-frequency
        v-show="formModel.execWay === 1"
        :value.sync="formModel.execFrequency"
        :weeks.sync="formModel.weeks"
        :months.sync="formModel.months"
        :days.sync="formModel.days"
        :mode="mode"
      />
      <!-- 执行间隔 -->
      <exec-interval
        v-show="formModel.execWay === 1"
        :value.sync="formModel.execInterval"
        :once-hms.sync="formModel.onceHms"
        :interval.sync="formModel.intervals"
        :unit.sync="formModel.unit"
        :start-hms.sync="formModel.startHms"
        :end-hms.sync="formModel.endHms"
        :mode="mode"
      />
      <!-- 持续时间 -->
      <exec-duration
        v-show="formModel.execWay === 1"
        :is-need-end-time.sync="isNeedEndTime"
        :start-time.sync="formModel.startTime"
        :end-time.sync="formModel.endTime"
        :mode="mode"
      />
      <div
        v-if="mode !== 'detail'"
        class="buttons-wrapper"
        style="text-align: center; margin-top: 20px"
      >
        <el-button @click="onCancel">
          {{ $t('lang.gles.common.cancel') }}
        </el-button>
        <el-button v-debounce="handleSubmit" :loading="vloading" type="primary">
          {{ $t('lang.gles.common.confirm') }}
        </el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import { getEditBaseFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import execType from './execType'
import ExecFrequency from './execFrequency.vue'
import ExecInterval from './execInterval.vue'
import ExecDuration from './execDuration.vue'
import { mapState } from 'vuex'
export default {
  name: 'PlanTaskAdd',
  components: {
    HeaderPath,
    execType,
    ExecFrequency,
    ExecInterval,
    ExecDuration
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      isNeedEndTime: false, // 持续时间  是否设置  结束时间
      formModel: {
        execWay: 1,
        execTime: '',
        execFrequency: 1,
        weeks: [],
        months: [],
        days: [],
        execInterval: 1,
        onceHms: '', // HH:mm:ss
        intervals: 1, // 重复间隔数
        unit: 'h', // 重复执行间隔数单位 h m s
        startHms: '', // 重复执行间隔 开始时间  HH:mm:ss
        endHms: '', // 重复执行间隔 结束时间 HH:mm:ss
        startTime: '',
        endTime: ''
      },
      // 任务类型列表
      execActionList: [],
      usedExecActionList: []
    }
  },
  computed: {
    ...mapState('workflow', ['planTaskTypeList']),
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.workflow.planTaskAdd')]
        : [this.$t('lang.gles.workflow.planTaskEdit')]
    },
    baseFormData() {
      return getEditBaseFormData(this, { ...this.formModel })
    }
  },
  created() {
    this.initSelectList()
    if (this.rowDetail?.id) {
      this.getExecActionList()
      this.getDetail({ id: this.rowDetail.id })
    }
    if (this.mode === 'add') {
      this.getSerialBizCode('planTaskSerialNum', 'planTaskCode')
    }
  },
  methods: {
    async initSelectList() {
      if (!this.planTaskTypeList?.length) {
        await this.$store.dispatch('workflow/getPlanTaskTypeList')
      }
      await this.getUsedExecActionList()
    },
    /**
     * 获取所有执行动作（理货策略）
     */
    async getExecActionList() {
      const { data, code } = await this.$httpService(
        this.$apiStore.strategyManage('getTallyDictList')
      )
      if (code) return
      const allList = (data || []).map((item) => {
        return {
          label: item.strategyName,
          value: item.id
        }
      })
      // 过滤已经创建了计划任务的执行动作
      this.execActionList = allList.filter((t) => {
        return !this.usedExecActionList.includes(
          `${t.value},${this.formModel.planTaskType}`
        )
      })
    },
    /**
     * 获取已关联计划任务的执行动作
     */
    async getUsedExecActionList() {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('getUsedExecActionList')
      )
      if (code) return

      let list = (data.relatedList || []).map((t) => {
        return `${t.execAction},${t.planTaskType}`
      })
      if (this.formModel.execAction) {
        list = list.filter(
          (t) =>
            t !== `${this.formModel.execAction},${this.formModel.planTaskType}`
        )
      }
      this.usedExecActionList = list
    },

    /**
     * 获取工位详情
     */
    async getDetail(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('queryPlanTaskDetail'),
        params
      )
      if (code) return
      this.formModel = data
    },
    handlePlanTaskTypeChange(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      if (!val) {
        this.execActionList = []
      } else {
        this.getExecActionList()
      }
    },
    /**
     * 保存
     */
    handleSubmit() {
      // 先校验
      this.$refs.myForm?.getValidateFormModel().then((model) => {
        // 校验执行计划
        if (this.formModel.execWay === 1) {
          if (!this.checkExecFrequency()) {
            return
          }
          if (!this.checkExecInterval()) {
            return
          }
          if (!this.checkExecDuration()) {
            return
          }
        } else {
          if (!this.formModel.execTime) {
            this.$message.error(
              this.$t('lang.gles.workflow.planTask.checkMsg0')
            )
            return
          }
        }
        this.submitForm()
      })
    },
    /**
     * 校验执行频率
     */
    checkExecFrequency() {
      // 周
      if (this.formModel.execFrequency === 2) {
        if (!this?.formModel?.weeks?.length) {
          this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg1'))
          return false
        }
      }
      // 月
      if (this.formModel.execFrequency === 3) {
        if (!this.formModel?.months?.length) {
          this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg2'))
          return false
        }
        if (!this.formModel?.days?.length) {
          this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg3'))
          return false
        }
      }
      return true
    },
    /**
     * 校验执行间隔
     */
    checkExecInterval() {
      if (this?.formModel?.execInterval === 1) {
        if (!this?.formModel?.onceHms) {
          this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg4'))
          return false
        }
      }
      if (this?.formModel?.execInterval === 2) {
        if (!this.formModel?.intervals || !this?.formModel?.unit) {
          this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg5'))
          return false
        }
        if (!this?.formModel?.startHms || !this?.formModel?.endHms) {
          this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg6'))
          return false
        }
      }
      return true
    },
    /**
     * 校验持续时间
     */
    checkExecDuration() {
      if (!this.formModel?.startTime) {
        this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg7'))
        return false
      }
      if (this.isNeedEndTime) {
        if (!this.formModel?.endTime) {
          this.$message.error(this.$t('lang.gles.workflow.planTask.checkMsg8'))
          return false
        }
      }
      return true
    },
    /**
     * 表单提交
     */
    submitForm() {
      console.log('params:', this.getSaveParams())
      this.saveOrUpdate(this.getSaveParams())
    },
    /**
     * 保存修改参数
     */
    getSaveParams() {
      const params = {
        planTaskCode: this.formModel.planTaskCode,
        planTaskName: this.formModel.planTaskName,
        planTaskType: this.formModel.planTaskType,
        execAction: Number(this.formModel.execAction) || null,
        remarks: this.formModel.remarks ?? null,
        id: this.formModel.id ?? null,
        execWay: this.formModel.execWay
      }
      if (this.formModel.execWay === 1) {
        params.execTime = ''
      }
      // 单次执行
      if (this.formModel.execWay === 2) {
        params.execTime = this.formModel.execTime
        return params
      }
      // 重复执行
      // 执行频率
      params.execFrequency = this.formModel.execFrequency
      if (params.execFrequency === 2) {
        params.weeks = this.formModel.weeks ?? []
        params.months = []
        params.days = []
      } else if (params.execFrequency === 3) {
        params.months = this.formModel.months ?? []
        params.days = this.formModel.days ?? []
        params.weeks = []
      }
      // 执行间隔
      params.execInterval = this.formModel.execInterval
      if (params.execInterval === 1) {
        params.onceHms = this.formModel.onceHms
        params.intervals = null
        params.unit = ''
        params.startHms = ''
        params.endHms = ''
      } else if (params.execInterval === 2) {
        params.intervals = this.formModel.intervals
        params.unit = this.formModel.unit
        params.startHms = this.formModel.startHms
        params.endHms = this.formModel.endHms
        params.onceHms = ''
      }

      // 持续时间
      params.startTime = this.formModel.startTime
      if (this.isNeedEndTime) {
        params.endTime = this.formModel.endTime
      }

      return params
    },
    /**
     * 添加
     */
    async saveOrUpdate(params) {
      this.vloading = true
      setTimeout(() => {
        this.vloading = false
      }, 500)
      const { code } = await this.$httpService(
        this.$apiStore.workflow('saveOrUpdatePlanTask'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    /**
     * 取消
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'PlanTaskList'
      })
    }
  }
}
</script>
