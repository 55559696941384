<template>
  <m-dialog
    :visible="visible"
    :title="$t('lang.gles.workflow.runRecords')"
    :center="false"
    :is-need-footer="false"
    @closed="handleClose"
    @input="handleClose"
  >
    <m-table
      :table-item="tableItem"
      :table-data="tableData"
      :page-data="pageData"
      :extend-config="tableExtendConfig"
      @pageChange="pageChange"
    >
      <template #planTaskStatusSlot="{ row }">
        <span class="plan-task-status">
          <i class="ui-dot-process" :style="{'--dotColor': [getStatusColor(row.state)]}" />
          {{ getDictLabel(resultStateList, row.state) }}
        </span>
      </template>
    </m-table>
    <div class="buttons-wrapper" style="text-align: center; margin-top: 20px">
      <el-button @click="handleClose">
        {{ $t('lang.gles.common.close') }}
      </el-button>
    </div>
  </m-dialog>
</template>
<script>
import { getRecordSearchTableItem } from '../data'
import { mapState } from 'vuex'
import listMixins from '@/mixins/listMixins'
export default {
  name: 'RunRecord',
  mixins: [listMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pageData: {
        pageSize: 10,
        pageNo: 1
      },
      tableData: []
    }
  },
  computed: {
    ...mapState('workflow', ['resultStateList']),
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getRecordSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  created() {
    if (this.visible) {
      this.getTableData()
    }
    this.tableExtendConfig.operate = []
  },
  methods: {
    async getTableData() {
      const row = this.rowDetail
      // 接口获取运行记录
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('queryPlanTaskRecords', this.pageData),
        {
          planTaskCode: row.planTaskCode,
          planTaskName: row.planTaskName
        }
      )
      if (code) return
      // 解构变量
      const {
        recordList = [],
        currentPage,
        pageSize,
        totalRecordCount: recordCount
      } = data || {}
      // 分页
      this.pageData = { currentPage, pageSize, recordCount }
      this.tableData = recordList || []
    },
    /**
     * 页码改变
     * @param {*} pageData
     */
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.getTableData()
    },
    /**
     * 任务状态 颜色
     * @param {*} state
     */
    getStatusColor(state) {
      return state === 1 ? '#00c07b' : '#e72f38'
    },
    /**
     * 关闭dialog
     */
    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.plan-task-status {
  position: relative;
  display: flex;
  align-items: center;
  // padding-left: 14px;
  span {
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:before {
    position: absolute;
    left: 0;
    content: ' ';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    // animation: ripple 1.5s ease infinite;
  }
  .ui-dot-process {
    background: var(--dotColor);
    &:after {
      border-color: var(--dotColor);
    }
  }
}
</style>
