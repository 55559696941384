<template>
  <div class="schedule-wrapper">
    <section class="schedule-item">
      <el-divider content-position="left">
        <span class="required-icon">*</span
        >{{ $t('lang.gles.workflow.execInterval') }}
      </el-divider>
      <el-radio-group
        :value="value"
        style="display: flex; flex-direction: column"
        :disabled="mode === 'detail'"
        @input="$emit('update:value', $event)"
      >
        <template v-for="item in execIntervalList">
          <el-radio
            v-if="item.value === 1"
            :key="item.value"
            :label="item.value"
            class="once-repeat-wrapper"
          >
            {{ item.label }}
            <el-time-picker
              :value="onceHms"
              :disabled="value === 2 || mode === 'detail'"
              value-format="HH:mm:ss"
              placeholder="请选择执行时间"
              @input="$emit('update:once-hms', $event)"
            />
          </el-radio>
          <el-radio
            v-if="item.value === 2"
            :key="item.value"
            :label="item.value"
            class="repeat-radio-wrapper"
          >
            {{ item.label }}
            <div class="repeat-content">
              <div class="left">
                <span>{{ $t('lang.gles.workflow.execInterval') }}：</span>
                <el-input-number
                  :value="interval"
                  :min="1"
                  controls-position="right"
                  :disabled="value === 1 || mode === 'detail'"
                  @input="$emit('update:interval', $event)"
                />
                <el-select
                  :value="unit"
                  :disabled="value === 1 || mode === 'detail'"
                  @input="$emit('update:unit', $event)"
                >
                  <el-option
                    v-for="u in unitList"
                    :key="u.value"
                    :value="u.value"
                    :label="u.label"
                  />
                </el-select>
              </div>
              <div class="right">
                <span>{{ $t('lang.gles.workflow.execTime') }}：</span>
                <el-time-picker
                  v-model="timeRange"
                  is-range
                  :disabled="value === 1 || mode === 'detail'"
                  :range-separator="$t('lang.gles.common.to')"
                  :start-placeholder="$t('lang.gles.common.startTime')"
                  :end-placeholder="$t('lang.gles.common.endTime')"
                  @change="handleTimeRangeChange"
                />
              </div>
            </div>
          </el-radio>
        </template>
      </el-radio-group>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'ExecInterval',
  props: {
    /**
     * 执行间隔
     */
    value: {
      type: Number,
      default: 1
    },
    /**
     * 单次执行间隔时间
     * HH:mm:ss
     */
    onceHms: {
      type: String,
      default: ''
    },
    /**
     * 重复执行间隔数
     */
    interval: {
      type: Number,
      default: 1
    },
    /**
     * 重复执行间隔数单位
     * h 小时
     * m 分钟
     * s 秒
     */
    unit: {
      type: String,
      default: 'h'
    },
    /**
     * 重复执行间隔开始时间
     * HH:mm:ss
     */
    startHms: {
      type: String,
      default: ''
    },
    /**
     * 重复执行间隔结束时间
     * HH:mm:ss
     */
    endHms: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      // timeRange: ''
    }
  },
  computed: {
    ...mapState('workflow', ['execIntervalList', 'unitList']),
    timeRange: {
      get() {
        let time = ''
        if (this.startHms && this.endHms) {
          const date = new Date()
          const dateStr = dayjs(date).format('YYYY-MM-DD')
          const startTime = dayjs(`${dateStr} ${this.startHms}`).toDate()
          const endTime = dayjs(`${dateStr} ${this.endHms}`).toDate()
          time = [startTime, endTime]
        }
        return time
      },
      set(val) {
        this.handleTimeRangeChange(val)
      }
    }
  },
  created() {
    this.initSelect()
    // this.initRangeTime()
  },
  methods: {
    initSelect() {
      if (!this.execIntervalList?.length) {
        this.$store.dispatch('workflow/getExecIntervalList')
      }
      if (!this.unitList?.length) {
        this.$store.dispatch('workflow/getExecIntervalUnitList')
      }
    },
    /**
     * 重复执行，执行时间
     * @param {*} val
     */
    handleTimeRangeChange(val) {
      let startHms = ''
      let endHms = ''
      if (val) {
        startHms = dayjs(val[0]).format('HH:mm:ss')
        endHms = dayjs(val[1]).format('HH:mm:ss')
      }
      this.$emit('update:start-hms', startHms)
      this.$emit('update:end-hms', endHms)
    }
  }
}
</script>
<style lang="scss" scoped>
.schedule-wrapper {
  .required-icon {
    margin-right: 4px;
    color: #f56c6c;
    font-size: 10px;
  }
  .el-radio-group {
    margin-right: 10px;
  }
}
</style>
<style lang="scss">
.once-repeat-wrapper {
  .el-date-editor {
    margin-left: 20px;
    width: 160px;
  }
}
.repeat-radio-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .repeat-content {
    display: flex;
    margin-left: 10px;
  }
  .el-radio__label {
    display: flex;
    align-items: center;
    .left,
    .right {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
    .el-input-number {
      margin-right: 10px;
    }
    .el-select {
      width: 100px;
    }
  }
}

.el-divider__text {
  font-size: 13px !important;
}
</style>
