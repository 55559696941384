<template>
  <div class="schedule-wrapper">
    <section class="schedule-item">
      <el-divider content-position="left">
        <span class="required-icon">*</span>{{ $t('lang.gles.workflow.execDuration') }}
      </el-divider>
      <div class="time-wrapper">
        <span class="label">{{ $t('lang.gles.common.startTime') }}</span>
        <el-date-picker
          :value="startTime"
          type="datetime"
          :picker-options="startTimeOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          :placeholder="$t('lang.gles.common.startTime')"
          :disabled="mode === 'detail'"
          @input="$emit('update:start-time', $event)"
        />
        <div class="end-time-wrapper">
          <el-checkbox
            :value="isNeedEndTime"
            :disabled="mode === 'detail'"
            @input="handleCheckAllChange"
          >
            {{ $t('lang.gles.common.endTime') }}
          </el-checkbox>
          <el-date-picker
            :value="endTime"
            type="datetime"
            :picker-options="startTimeOptions"
            :disabled="!isNeedEndTime || mode === 'detail'"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            :placeholder="$t('lang.gles.common.endTime')"
            @input="$emit('update:end-time', $event)"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'ExecDuration',
  props: {
    isNeedEndTime: {
      type: Boolean,
      default: false
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      startTimeOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime()
        }
      }
    }
  },
  watch: {
    endTime() {
      if (this.endTime) {
        this.isNeedEndTime = true
      }
    }
  },
  methods: {
    handleCheckAllChange(val) {
      this.$emit('update:is-need-end-time', val)
      if (!this.isNeedEndTime) {
        this.$emit('update:end-time', '')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.schedule-wrapper {
  .required-icon {
    margin-right: 4px;
    color: #f56c6c;
    font-size: 10px;
  }
  .el-radio-group {
    margin-right: 10px;
  }
  .time-wrapper {
    display: flex;
    align-items: center;
    .label {
      margin-right: 10px;
    }
    .end-time-wrapper {
      margin-left: 40px;
      .el-checkbox {
        margin-right: 10px;
      }
    }
  }
  .el-divider__text {
    font-size: 13px !important;
  }
}
</style>
