<template>
  <div class="schedule-wrapper">
    <section class="schedule-item">
      <el-divider content-position="left">
        <span class="required-icon">*</span>{{ $t('lang.gles.workflow.execWay') }}
      </el-divider>
      <el-radio-group
        :value="value"
        :disabled="mode === 'detail'"
        @input="$emit('update:value', $event)"
      >
        <el-radio
          v-for="item in execWayList"
          :key="item.value"
          :label="item.value"
        >
          {{ item.label }}
        </el-radio>
      </el-radio-group>
      <el-date-picker
        :value="execTime"
        type="datetime"
        :picker-options="startTimeOptions"
        :disabled="value === 1 || mode === 'detail'"
        value-format="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm:ss"
        :placeholder="$t('大于当前时间')"
        @input="handleExecTimeChange"
      />
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ExecType',
  props: {
    value: {
      type: Number,
      default: 1
    },
    execTime: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      startTimeOptions: {
        disabledDate: time => {
          const now = new Date()
          now.setDate(now.getDate() - 1)
          return time.getTime() < now.getTime()
        }
      },
      execTimePicker: ''
    }
  },
  computed: {
    ...mapState('workflow', ['execWayList'])
  },
  methods: {
    handleRadioChange($event) {
      this.$emit('update:value', $event)
    },
    handleExecTimeChange($event) {
      this.$emit('update:execTime', $event)
    }
  }
}
</script>
<style lang="scss" scoped>
.schedule-wrapper {
	.required-icon {
		margin-right: 4px;
		color: #f56c6c;
		font-size: 10px;
	}
	.el-radio-group {
		margin-right: 10px;
	}
  .el-divider__text {
    font-size: 13px !important;
  }
}
</style>
