export default {
  data() {
    return {
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      },
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: true
      },
      button: {
        ok: '确定',
        cancel: '取消'
      },
      formModel: {},
      isSimpleModel: true,
      autoGeneCodeTip:
        'lang.gles.common.autoGenerateAUniqueCodeOf2lettersAnd6DigitsWithin50Chars',
      vloading: false
    }
  },
  watch: {
    baseFormData() {
      this.$nextTick(() => {
        this.$refs?.myForm?.changeFormValue(this.formModel ?? {})
      })
    },
    rowDetail: {
      handler() {
        if (this.mode === 'edit' && this.isSimpleModel) {
          this.formModel = this._.cloneDeep(this.rowDetail)
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * 获取字典label值
     * @param {*} list 字典列表
     * @param {*} key 字典key
     */
    getDictLabel(list = [], value) {
      return list?.find((t) => t.value === value)?.label ?? value
    },
    /**
     * 表单项改变事件
     */
    onFormChange(val, formItem) {
      const newVal = typeof val === 'string' ? this._.trim(val) : val
      this.$set(this.formModel, formItem.name, newVal)
      this.$emit('update:rowDetail', this._.cloneDeep(this.formModel))
    },
    /**
     * 获取自增编码
     * @param {*} bizType 业务类型
     * @param {*} codeKey code对应字段名
     */
    async getSerialBizCode(bizType = '', codeKey) {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('getSerialBizCode'),
        { bizType }
      )
      if (code !== 0) return
      if (this.isSimpleModel) {
        this.$emit('update:row-detail', {
          ...this.rowDetail,
          [codeKey]: data
        })
        this.$set(this.formModel, codeKey, data)
      }
      return data
    }
  }
}
