<template>
  <el-dialog
    class="more-query-dialog"
    :visible="visible"
    :title="$t('lang.gles.common.moreQuery')"
    :append-to-body="true"
    :width="width"
    @update:visible="$emit('update:visible', $event)"
  >
    <m-form
      ref="moreQueryData"
      :form-data="form"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
    />
    <div slot="footer" class="footer">
      <el-button icon="el-icon-refresh-left" @click="reset">
        {{ $t('lang.gles.common.reset') }}
      </el-button>
      <el-button type="primary" icon="el-icon-search" @click="query">
        {{ $t('lang.gles.common.query') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'MoreQuery',
  props: {
    queryList: {
      type: Array,
      default: () => []
    },
    searchApi: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '50%'
    }
  },
  inject: ['handleReset'],
  data() {
    return {
      form: {},
      defaultForm: {},
      extendFormConfig: {
        isNeedBtn: false
      }
    }
  },
  watch: {
    queryList() {
      this.form = this._.cloneDeep(this.queryList)
    },
    moreQueryData() {
      this.$nextTick(() => {
        this.$refs.myForm.changeFormValue(this.moreQueryFormModel ?? {})
      })
    }
  },
  created() {
    this.form = this._.cloneDeep(this.queryList)
  },
  methods: {
    reset() {
      this.form = this._.cloneDeep(this.queryList)
      this.$refs.moreQueryData.formModel = {}
      this.handleReset({})
      // this.$EventBus.$emit('reset', {})
    },
    query() {
      this.$emit('update:visible', false)
      this.$emit('query', this.$refs.moreQueryData.formModel)
    }
  }
}
</script>
<style lang="scss">
.more-query-dialog {
  .query-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .el-form-item {
      flex: 0 0 45%;
      max-width: 45%;
    }
    .el-select {
      width: 100%;
    }
  }
  .el-dialog {
    position:absolute;
    right:0;
  }
  .el-dialog__header {
    border-bottom: 1px solid #eeee;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__footer {
    text-align: center;
  }
}
</style>
